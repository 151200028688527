import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { Link } from 'gatsby';

import GlobalContext from '../../context/GlobalContext';
import Offcanvas from '../Offcanvas';
import NestedMenu from '../NestedMenu';
import { device } from '../../utils';
import Logo from '../Logo';
import { menuItems } from './menuItems';

const SiteHeader = styled.header`
	padding: 0;
	position: absolute !important;
	top: 0;
	right: 0;
	width: 100%;
	z-index: 999;
	@media ${device.lg} {
		position: fixed !important;
		transition: 0.4s;
		&.scrolling {
			transform: translateY(-100%);
			transition: 0.4s;
		}
		&.reveal-header {
			transform: translateY(0%);
			box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
			z-index: 9999;
			background: ${({ dark, theme }) => theme.colors.bg};
		}
	}
`;

const ToggleButton = styled.button`
	color: ${({ dark, theme }) => theme.colors.front}!important;
	border-color: ${({ dark, theme }) => theme.colors.front}!important;
`;

const Menu = styled.ul`
	@media ${device.lg} {
		display: flex;
		justify-content: flex-end;
	}
	.dropdown-toggle {
		cursor: pointer;
	}
	> li {
		> .nav-link {
			@media ${device.lg} {
				color: ${({ dark, theme }) => theme.colors.front}!important;
				transition: 0.4s;
				align-items: center;
				display: inline-flex;
				font-size: 15px;
				font-weight: 700;
				line-height: 24px;
				padding-top: 18px !important;
				padding-bottom: 18px !important;
				padding-left: 18px !important;
				padding-right: 18px !important;

				text-transform: lowercase;

				&.dropdown-toggle:after {
					margin-left: 10px;
					position: relative;
					transform: rotate(90deg);
					top: 1px;
					content: '\\ea06';
					border: none;
					font-family: 'Grayic';
					font-size: 24px;
					transition: 0.4s;
					font-weight: 900;
					line-height: 14px;
				}
			}
		}
		&.nav-item:hover > .nav-link,
		&.nav-item.active > .nav-link {
			color: ${({ theme }) => theme.colors.primary} !important;
			&:after {
				transform: rotate(-90deg);
			}
		}
	}
	.nav-item.dropdown {
		@media ${device.lg} {
			position: relative;
			z-index: 99;
		}
		&:hover {
			> .menu-dropdown {
				@media ${device.lg} {
					top: 90%;
					right: 0;
					opacity: 1;
					pointer-events: visible;
				}
			}
		}
	}
`;

const MenuDropdown = styled.ul`
	list-style: none;
	@media ${device.lg} {
		top: 110%;
		position: absolute;
		min-width: 227px;
		max-width: 227px;
		box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);

		background-color: ${({ dark, theme }) => theme.colors.light};
		padding: 15px 0px;
		z-index: 99;
		opacity: 0;
		transition: opacity 0.4s, top 0.4s;
		pointer-events: none;
		left: -90%;
		border: 1px solid #e5e5e5;
		border-radius: 0 0 10px 10px;
		display: block;
		border-top: ${({ theme }) => `3px solid ${theme.colors.primary}`};
	}
	> .drop-menu-item {
		color: ${({ theme }) => theme.colors.dark};
		font-size: 15px;
		font-weight: 300;
		letter-spacing: -0.5px;
		padding-left: 30px;
		padding-right: 30px;
		padding-top: 10px;
		padding-bottom: 10px;

		a {
			color: ${({ theme }) => theme.colors.dark} !important;
			transition: all 0.3s ease-out;
			position: relative;
			display: flex;
			align-items: center;
			font-weight: 700;
			text-transform: lowercase;
			&.dropdown-toggle::after {
				margin-left: 10px;
				position: relative;
				top: 1px;
				content: '\\ea06';
				border: none;
				font-family: 'Grayic';
				font-size: 24px;
				transition: 0.4s;
				font-weight: 900;
				line-height: 14px;
			}
		}

		&:hover,
		&.active {
			> a {
				color: ${({ theme }) => theme.colors.primary} !important;
				text-decoration: none;
				&::after {
					transform: rotate(180deg);
				}
			}
		}
		&.dropdown {
			position: relative;

			&:hover {
				> .menu-dropdown {
					@media ${device.lg} {
						top: 10px;
						opacity: 1;
						pointer-events: visible;
						transform: translateX(-100%);
					}
				}
			}
			> .menu-dropdown {
				border-top-color: ${({ theme }) => theme.colors.success};
				@media ${device.lg} {
					top: 10px;
					left: 0%;
					opacity: 0;
					transform: translateX(-110%);
					transition: 0.4s;
					pointer-events: none;
				}
				> .drop-menu-item {
					@media ${device.lg} {
						padding-left: 30px;
						padding-right: 30px;
					}
				}
			}
		}
	}
	&.dropdown-right {
		left: auto;
		right: -90%;
	}
`;

const Header = ({ isDark }) => {
	const gContext = useContext(GlobalContext);
	const [showScrolling, setShowScrolling] = useState(false);
	const [showReveal, setShowReveal] = useState(false);

	useScrollPosition(({ prevPos, currPos }) => {
		if (currPos.y < 0) {
			setShowScrolling(true);
		} else {
			setShowScrolling(false);
		}
		if (currPos.y < -300) {
			setShowReveal(true);
		} else {
			setShowReveal(false);
		}
	});

	return (
		<>
			<SiteHeader
				className={`sticky-header ${showScrolling ? 'scrolling' : ''} ${
					showReveal ? 'reveal-header' : ''
				}`}
				dark={isDark ? 1 : 0}
			>
				<Container fluid>
					<nav className="navbar px-0 px-md-3 site-navbar offcanvas-active navbar-expand-lg navbar-light">
						{/* <!-- Brand Logo--> */}
						<div className="brand-logo">
							<Logo color={isDark ? 'light' : 'dark'} />
						</div>
					</nav>
				</Container>
			</SiteHeader>
		</>
	);
};
export default Header;
