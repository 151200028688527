import React from 'react';
import { Link } from 'gatsby';
import { Title } from '../Core';

const Logo = ({ color = 'front', height, className = '', ...rest }) => {
	return (
		<Link to="/" className={`${className}`} {...rest}>
			<Title color="#53b9a4" variant="cardLg" className="mb-0">
				Typo.
			</Title>
		</Link>
	);
};

export default Logo;
